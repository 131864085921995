import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

import { Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles'
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { AppBar, Toolbar, CssBaseline, Typography } from "@mui/material";

import styles from './cabecalho.module.css';
import Toast from '../../components/Toast/Toast';

import loginService from '../../paginas/login/service/login.service';
import { UserContext } from '../../contexts/UserContext';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NavItem from '../../components/NavItem/NavItem';

const useStyles = makeStyles((theme: any) => ({
    header: {
        background: '#053616',
        height: '10vh',
    },
    navlinks: {
        display: "flex",
        textDecoration: "none",
        marginRight: 5,
    },
    logo: {
        flexGrow: "2",
        cursor: "pointer",
    },
}));


export default function Cabecalho() {
    const classes = useStyles();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isToast, setIsToast] = useState(false);
    const [user, setUser] = useState('');
    const [isMenuOpen, setOpenMenu] = useState(false);
    const { user_role } = useContext(UserContext);
    const [inicio, setInicio] = useState('');
    
    const sair = () => {
        setIsToast(true);
        setUser('');

        setTimeout(() => {
            const resp = loginService.logout()
            setIsToast(false);
        }, 100)
    }

    const [isToken, setIsToken] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loginService.observable.onToken().subscribe((token: any) => {
            if (token) {
                setIsToken(true);
                setInicio('/pagina-inicial')
            }
            if (token === null) {
                setIsToken(false);
                setInicio('/')
            }
        });
        loginService.observable.setToken(loginService.getToken())

    }, []);

    useEffect(() => {
        const handleResize = () => {
           setWindowWidth(window.innerWidth);
         };
     
         window.addEventListener('resize', handleResize);
     
         return () => {
           window.removeEventListener('resize', handleResize);
         };
     }, []);

     function toggleNavMenu() {
        setOpenMenu(!isMenuOpen);
     }
 
    return (
        // <div className={styles.cabecalho}>
        <>
            <Toast open={isToast} mensagem={'Ate logo!'} timer={3000} />
            {
                windowWidth > 800
                ?
                <AppBar position="static">
                    <CssBaseline />
                    <Toolbar className={styles.header}>
                        <Typography>
                            <span>
                                <div className={styles.grid}>
                                        <Link to={inicio} className={styles.logo}>
                                            <img src="../assets/imagens/logoOGES.png" style={{ height: '70px' }} />
                                        </Link>
                                </div>
                            </span>
                        </Typography>
                        <div className={classes.navlinks}>
                            {isToken ?
                                <>
                                    <div className={styles.menuContainer}>
                                        <div className={styles.menuGrid}>
                                            <div className={styles.itemsGrid}>
                                                <div className={styles.menuItem}>
                                                    <div className={styles.dropdown}>
                                                        <button className={styles.dropdownBtn}>
                                                            Garimpo
                                                            <ArrowDown />
                                                        </button>
                                                        <div className={styles.dropdownContent}>
                                                            <Link to="list-denuncia-garimpo">
                                                                <span>Listar formulários</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                {(user_role == 1 || user_role == 2) &&
    
                                                    <div className={styles.menuItem}>
                                                        <div className={styles.dropdown}>
                                                            <button className={styles.dropdownBtn}>
                                                                Usuários
                                                                <ArrowDown />
                                                            </button>
                                                            <div className={styles.dropdownContent}>
                                                                <Link to="list-usuario">
                                                                    <span>Listar usuários</span>
                                                                </Link>
                                                                <Link to="create-usuario">
                                                                    <span>Criar usuário</span>
                                                                </Link>                                                                
                                                                { (user_role == 1) &&
                                                                <Link to="gerenciamento-aplicacao">
                                                                    <span>Gerenciamento</span>
                                                                </Link>
                                                                }
                                                                <Link to="reset-password">
                                                                    <span>Redefinir senha</span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            
                                            <Link to="pagina-inicial" className={styles.link} onClick={sair} title='Sair'>
                                                <LogoutIcon sx={{ fontSize: 35 }} />
                                            </Link>
                                        </div>
                                    </div>
                                </>
                                :
                                <Link to="login" className={styles.link} title='Entrar'>
                                    <AccountCircle sx={{ fontSize: 35 }} />
                                </Link>
                            }
                        </div>
                    </Toolbar>
                </AppBar>
                :
                <section className={styles.hamburgerMenu}>
                    <div className={styles.menuOptions}>
                        <Link to='/' className={styles.logo}>
                            <img src="../assets/imagens/logoOGES.png" style={{ height: '50px' }} />
                        </Link>
                        <button onClick={toggleNavMenu}>
                            <MenuIcon style={{ color: 'white', fontSize: 40 }} />
                        </button>
                    </div>

                    {isMenuOpen && 
                        <>
                            <div className={styles.menuOverlay}></div>
                            <div className={styles.menuOpened}>
                                <div>
                                    <button className={styles.closeNavMenu} onClick={toggleNavMenu}>
                                        <CloseIcon style={{ color: 'white', fontSize: 40 }} />
                                    </button>
                                </div>
                                <div>
                                    {
                                        isToken &&
                                        <div className={styles.menuNavOptions}>                        
                                            <NavItem title="Garimpo Ilegal">
                                                <button onClick={event =>  window.location.href='/list-denuncia-garimpo'}>
                                                    <span>Listar formulários</span>
                                                </button>
                                            </NavItem>
                                            <NavItem title="Usuários">
                                                <button onClick={event =>  window.location.href='/list-usuario'}>
                                                    <span>Listar usuários</span>
                                                </button>
                                                <button onClick={event =>  window.location.href='/create-usuario'}>
                                                    <span>Criar usuário</span>
                                                </button>
                                                <button onClick={event =>  window.location.href='/gerenciamento-aplicacao'}>
                                                    <span>Gerenciamento</span>
                                                </button>
                                                <button onClick={event =>  window.location.href='/reset-password'}>
                                                    <span>Redefinir senha</span>
                                                </button>
                                            </NavItem>
                                        </div>                                        
                                    }
                                </div>
                                <div className={styles.footerLink}>
                                    {
                                        isToken
                                        ?
                                        <Link to="pagina-inicial" onClick={sair} title='Sair'>
                                                <LogoutIcon style={{ color: 'white', fontSize: 40 }} />
                                        </Link>
                                        :
                                        <a href="login" title='Entrar'>
                                            <AccountCircle style={{ color: 'white', fontSize: 40 }} />
                                        </a>
                                    }
                                    <p className={styles.copyright}>Observatório Nacional da DPU Sobre Garimpo e Efeitos Socioambientais - OGES &copy;</p>
                                </div>
                            </div>
                        </>
                    }
                </section>
            }
        </>
    )
}