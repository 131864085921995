import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import styles from './PaginaInicial.module.css';
import { Link } from 'react-router-dom';
import Head from '../../helper/Head';
import { env } from '../../../env/env';

const urlBack: string = (env.baseURL as string);

export default function PaginaInicial() {
   const { user_role, user_name } = useContext(UserContext);

   return (
      <div className={styles.section}>
         <div className={styles.gridChart}>
            <div className={styles.chartItem}>
               <Card>
                  <span style={{ fontSize: 20, display: 'flex', justifyContent: 'center', padding: '1rem' }}><b>Numero de denúncia por estado</b></span>
                  <CardContent>
                     <div>
                        <iframe style={{ width: '99%', height: 820 }} src={urlBack + 'grafico3'}></iframe>
                     </div>
                  </CardContent>
               </Card>
            </div>
            <div className={styles.chartItem}>
               <Card>
                  <CardContent>
                  <span style={{ fontSize: 20, display: 'flex', justifyContent: 'center', padding: '1rem' }}><b>Gráfico de atividade de garimpo ilegal </b></span>
                     <div>
                        <iframe style={{ width: '99%', height: 820 }} src={urlBack + 'grafico1'}></iframe>
                     </div>
                  </CardContent>
               </Card>
            </div>
            <div className={styles.chartItem}>
               <Card>
                  <CardContent>
                  <span style={{ fontSize: 20, display: 'flex', justifyContent: 'center', padding: '1rem' }}><b>Gráfico de status das denúncia </b></span>
                     <div>
                        <iframe style={{ width: '99%', height: 550 }} src={urlBack + 'grafico2'}></iframe>
                     </div>
                  </CardContent>
               </Card>
            </div>
         </div>
      </div>
   )
}
