import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import styles from './ListaStatus.module.css';
import gerenciamentoAplicacaoService from './service/gerenciamentoAplicacao.service';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { useForm } from 'react-hook-form';
import Toast from '../../components/Toast/Toast';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import Dialogo from '../../components/Dialogo/Dialogo';
import ProgressoCircular from '../../components/ProgressoCircular/ProgressoCircular';

export default function ListaStatus() {
   const { register, handleSubmit, watch, setValue, clearErrors, setError, formState: { errors } } = useForm();
   const [listStatus, setListStatus] = useState([] as any);
   const [toastMessage, setToastMessage] = useState('');
   const [toastIcon, setToastIcon] = useState('');
   const [isToast, setIsToast] = useState(false);
   const [openDialog, setOpenDialog] = useState(false);
   const [isOpenProgressoCircular, setIsOpenProgressoCircular] = useState(false);

   const [id, setId] = useState('');

   const cookies = new Cookies();
   const navigate = useNavigate();

   const role = cookies.get('user').usuario.role_id;

   if (role != 1) navigate('/pagina-inicial');

   useEffect(() => {
      listaStatus();
   }, []);

   const listaStatus = async () => {
      const resp = await gerenciamentoAplicacaoService.listStatus();

      const filtro = resp.filter((e:any) => parseInt(e.id) != 4);

      setListStatus(filtro)
   }

   const [open, setOpen] = React.useState(false);

   const handleDialogStatus = (e: any) => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
      setValue('name', null);
      setValue('type', null);
   };

   const onSubmit = async (data: any) => {
      setOpenDialog(false)
      setIsOpenProgressoCircular(true)

      const resp = await gerenciamentoAplicacaoService.createStatus(data);

      if (resp.status) {
         setToastMessage('Status criado com sucesso!');
         setToastIcon('success');
         setIsToast(true);
         setOpen(false);
         listaStatus();
      } else {
         resp.error.data.forEach((e: any) => {
            setError(e.label, { message: e.erro });
         })
      }

      setValue('id', null);
      setValue('name', null);
      setValue('type', null);

      setIsOpenProgressoCircular(false)

      setTimeout(() => {
         setIsToast(false);
      }, 100)

   }

   const handleDialogSubmit = async () => {
      setOpenDialog(false)
      setIsOpenProgressoCircular(true)

      const resp = await gerenciamentoAplicacaoService.deleteStatusById(id);

      if (resp.status) {
         listaStatus();
         setToastMessage('Status desativado com sucesso!');
         setToastIcon('success');
         setIsToast(true);
      } else {
         setToastMessage('Ouve uma falha!');
         setToastIcon('error');
         setIsToast(true);
         console.error(resp);
      }

      setIsOpenProgressoCircular(false)
      setTimeout(() => {
         setIsToast(false);
      }, 100)
   }
   return (
      <section className={`animeRight container-internal ${styles.status}`}>
         <Toast open={isToast} icon={toastIcon} mensagem={toastMessage} timer={3000} />

         <Card>
            <CardContent>
               <div className={styles.cardContent}>
                  <h3 className="section-title">
                     Listagem de Status &nbsp;
                     <Button variant="contained" onClick={handleDialogStatus} >Adicionar Status</Button>
                  </h3>

                  <div className={styles.tableStatus}>
                     <table className={`table table-bordered`} border={1}>
                        <thead>
                           <tr>
                              <th scope="col">Id</th>
                              <th scope="col">Nome</th>
                              <th scope="col">Tipo</th>
                              <th scope="col">Desativar</th>
                           </tr>
                        </thead>
                        <tbody>
                           {listStatus.length > 0 ?
                              listStatus.map((e: any, i: any) =>
                                 <tr key={i}>
                                    <td >{e.id}</td>
                                    <td style={{color: e.ativo == 0 ? 'gray' : 'black'}}>{e.name}</td>
                                    <td style={{color: e.ativo == 0 ? 'gray' : 'black'}}>{e.type == 1 ? 'Status denúncia' : 'Status acompanhamento'}</td>
                                    <td className={styles.actionsList}>
                                       <Button onClick={() => { setOpenDialog(true); setId(e.id) }} disabled={e.ativo == 0 ? true : false}><DeleteIcon /></Button>
                                    </td>
                                 </tr>
                              )
                              :
                              <tr>
                                 <td colSpan={5} className={styles.noResults}>
                                    <p>Sem status.</p>
                                 </td>
                                 <td style={{ display: 'none' }}>
                                 </td>
                              </tr>
                           }
                        </tbody>
                     </table>
                  </div>
               </div>

            </CardContent>
         </Card>

         <Dialog open={open} onClose={handleClose} >
            <DialogTitle> Criar status</DialogTitle>
            <DialogContent sx={{ width: 500 }}>
               <form onSubmit={handleSubmit(onSubmit)} autoComplete='off' >
                  <div >
                     <TextField
                        {...register('name', { required: false })}
                        className='mb-2 mt-2 w-100'
                        label="Nome (*)"
                        variant="outlined"
                        type='text'
                        autoComplete='off'
                        error={errors.name ? true : false}
                        helperText={errors.name && errors.name.message?.toString()}
                     />
                  </div>
                  <div>
                     <FormControl error={errors.type ? true : false} style={{ width: '100%' }} className='mt-2'>
                        <InputLabel>Tipo de status (*)</InputLabel>
                        <input {...register('type', { required: false })} hidden />
                        <Select
                           className='w-100'
                           defaultValue={watch('type')}
                           label='Tipo de status (*)'
                           onChange={(e) => setValue('type', e.target.value)}
                        >
                           <MenuItem value={'1'} >Status denúncia</MenuItem>
                           <MenuItem value={'2'} >Status acompanhamento</MenuItem>
                        </Select>
                        {errors.type &&
                           <FormHelperText>{errors.type.message?.toString()}</FormHelperText>
                        }
                     </FormControl>
                  </div>

                  <div className='mt-5' style={{ float: 'right' }}>
                     <Button variant="contained" color="error" onClick={handleClose}>Cancelar</Button>
                     &nbsp;
                     <Button variant="contained" type='submit' >Salvar</Button>
                  </div>
               </form>
            </DialogContent>
         </Dialog>

         <Dialogo
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            handleDialogSubmit={handleDialogSubmit}
            titulo={'Deseja realmente desativar esse status do sistema?'}
            conteudo={'Após desativar esse status não será possível usar ele novamente.'}
         />

         <ProgressoCircular open={isOpenProgressoCircular} />

      </section>
   )
}