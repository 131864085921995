import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'universal-cookie';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Toast from '../../components/Toast/Toast';

import { 
  FormHelperText, 
  FormControl,
  InputLabel, 
  MenuItem, 
  Select, 
  SelectChangeEvent, 
  Typography 
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import styles from './ResetPasswordUsuario.module.css';
import Head from '../../helper/Head';
import usuarioService from './service/usuario.service';
import loginService from '../../paginas/login/service/login.service';
import Loading from '../../components/Loading/Loading';

const ResetPasswordUsuario = () => {
  const { register, handleSubmit, watch, setValue, clearErrors, setError, formState: { errors } } = useForm();
  const [passwordError, setPasswordError] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastIcon, setToastIcon] = useState('');
  const [loading, setLoading] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const params = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();

  useEffect(() => {
      if(!cookies.get('token') || !cookies.get('user').id)
         navigate('/login');
  }, []);

  const onSubmit = async (data: any) => {
      setLoading(true);
      if (data.password !== data.confirmPassword) {
         setPasswordError(true);
         setLoading(false);
      }
      else {
         setPasswordError(false);
         let dataToUpdate = { 
            'idUsuario': cookies.get('user').id,
            'newPassword': data.confirmPassword, 
         };
     
         const resp = await usuarioService.updatePassword(dataToUpdate);

         if(resp && resp.response) {
            setToastMessage('Senha alterada com sucesso!');
            setToastIcon('success');
            setIsToast(true);

            setTimeout(() => {
                  cookies.remove('token', { path: '/' });
                  cookies.remove('user', { path: '/' });
                  setIsToast(false);
                  navigate(0);      
            }, 3000)
         }
         else {
            setToastMessage('Erro ao alterar a senha. Tente novamente mais tarde!');
            setToastIcon('error');
            setIsToast(true);
            setLoading(false);
         }         
      }
   }

  return (
    <section className={`animeRight container-internal ${styles.resetPassword}`}>
      <Toast open={isToast} icon={toastIcon} mensagem={toastMessage} timer={3000} />
      <Head title=" | Redefinir Senha" />
         <Card className="cardSection">
            <CardContent>
               <div className={styles.cardContent}>
                  <h2 className="section-title">Redefinir Senha</h2>
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                     <div className={`row p-2 ${styles.form}`}>
                        <TextField
                           {...register('password', { required: false })}
                           className='mb-2 w-100'
                           label="Nova senha (*)"
                           variant="outlined"
                           type='password'
                           autoComplete='off'
                           error={errors.password ? true : false}
                           helperText={errors.password && errors.password.message?.toString()}
                        />   

                        <TextField
                           {...register('confirmPassword', { required: false })}
                           className='mb-2 w-100'
                           label="Confirme a nova senha (*)"
                           variant="outlined"
                           type='password'
                           autoComplete='off'
                           error={errors.confirmPassword ? true : false}
                           helperText={errors.confirmPassword && errors.confirmPassword.message?.toString()}
                        />   

                        { passwordError &&  <p className={styles.passwordError}>As senhas não coincidem</p>}                   
                        
                        {
                           loading ? 
                           <Loading isLoading={loading} />
                           :
                           <Button variant="contained" type='submit'>
                              Salvar
                           </Button>
                        }
                     </div>
                  </form>
               </div>
            </CardContent>
         </Card>
    </section>
  )
}

export default ResetPasswordUsuario