import React, { useState } from 'react'

import ArrowDown from '@mui/icons-material/KeyboardArrowDown';

import styles from './NavItem.module.css';

const NavItem = (props: any) => {
    const [showOptions, setShowOptions] = useState(false);

    return (
    <div className={styles.navItem}>
        <div className={`${styles.navMenuItem} ${showOptions && styles.openedItem}`} onClick={() => { setShowOptions(!showOptions) }}>
            {props.title}        
            <ArrowDown />
        </div>

        { 
            showOptions &&
            <div className={styles.navSubMenuItem}>
                {props.children}
            </div>
        }
    </div>
    )
}

export default NavItem