import api from '../../../../config/api.service';
import Cookies from 'universal-cookie';

class GerenciamentoAplicacao {
    protected cookies = new Cookies();

    async listStatus() {
        const resp = await api.get('listStatus')
            .then((resp) => resp.data)
            .catch((err) => err.response.data.message);

        return resp;
    }

    async updateStatus(data: any) {
        const resp = await api.put('updateStatus', data)
            .then((resp) => resp.data)
            .catch((err) => err.response.data.message)

        return resp;
    }

    async deleteStatusById(id: any) {
        const resp = await api.put('deleteStatusById', { id })
            .then((resp) => resp.data)
            .catch((err) => err.response.data.message);

        return resp;
    }

    async createStatus(data: any) {
        const resp = await api.post('createStatus', data)
            .then((resp) => resp.data)
            .catch((err) => err.response.data.message);

        return resp;
    }

}

export default new GerenciamentoAplicacao();
