import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import usuarioService from './service/usuario.service';
import Head from '../../helper/Head';
import { Card, CardContent } from '@mui/material';
import Toast from '../../components/Toast/Toast';
import styles from './VerificarEmailUsuario.module.css';
import loginService from '../login/service/login.service';
import Loading from '../../components/Loading/Loading';

const VerificarEmailUsuario = () => {
    const params = useParams();
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isToast, setIsToast] = useState(false);
    const [toastIcon, setToastIcon] = useState('');
    const [toastMessage, setToastMessage] = useState('');

    useEffect(() => {
        setLoading(true);
        const validateUserEmail = async (data: any) => {
            let validateInfo = data.hash + '?expires=' + data.expires + '&signature=' + data.signature;
            const resp = await usuarioService.validateEmail({ 'id': parseInt(data.id), 'hash': validateInfo});

            if(resp.response.code == 201) {                  
                loginService.setCookieToResetPassword(resp);
                setMessage(resp.response.message + ' Aguarde enquanto redirecionamos você para a próxima página! :)');
                setTimeout(() => {
                    navigate('/reset-password');
                }, 1000);
            }
            else {
                setLoading(false);
                setIsToast(true);
                setToastMessage('Redirecionando para a página de login!');
                setToastIcon('success');
                setMessage(resp.response.message);
                setTimeout(() => {
                    setIsToast(false);
                    navigate('/login');      
                }, 3000)
            }
        }

        validateUserEmail(params);
    }, []);

    return (    
        <section className={`animeRight container-internal ${styles.verifyEmail}`}>
            <Toast open={isToast} icon={toastIcon} mensagem={toastMessage} timer={3000} />
        <Head title=" | Verificação E-mail" />
            <Card>
                <CardContent>
                <div className={styles.cardContent}>
                    <h2 className="section-title">Verificação de e-mail</h2>
                    {message && <p className={styles.message}>{ message }</p>}
                    {loading && <Loading isLoading={loading} /> }
                </div>
                </CardContent>
            </Card>
        </section>
    )
}

export default VerificarEmailUsuario