import React, { useEffect, useState } from 'react'
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ limiter, navigateTo }) => {
    let auth = { 'limiter': limiter }

    return (
        auth.limiter ? <Outlet/> : <Navigate to={navigateTo} />
    )
}

export default ProtectedRoutes