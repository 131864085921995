import React, { useEffect, useState, useContext } from 'react';
import { Route, Routes, useNavigate, } from 'react-router-dom';

import loginService from './paginas/login/service/login.service';

import Cabecalho from './layout/cabecalho/Cabecalho';
import Inicio from '../app/paginas/inicio/Inicio';
import Login from '../app/paginas/login/Login';

import PaginaInicial from '../app/paginas/paginaInicial/PaginaInicial';

import CreateUsuario from '../app/paginas/usuario/CreateUsuario';
import EditUsuario from '../app/paginas/usuario/EditUsuario';
import ListUsuarios from '../app/paginas/usuario/ListUsuarios';
import ResetPasswordUsuario from '../app/paginas/usuario/ResetPasswordUsuario';

import DenunciaGarimpo from './paginas/garimpoIlegal/DenunciaGarimpo';
import CreateDenunciaGarimpo from './paginas/garimpoIlegal/CreateDenunciaGarimpo';
import ListDenunciaGarimpo from './paginas/garimpoIlegal/ListDenunciaGarimpo';
import AcompanharNumeroDenunciaGarimpo from './paginas/garimpoIlegal/AcompanharNumeroDenunciaGarimpo';
import ResumoDenunciaGarimpo from './paginas/garimpoIlegal/ResumoDenunciaGarimpo';
import VerDenunciaGarimpo from './paginas/garimpoIlegal/VerDenunciaGarimpo';

import { UserContext } from './contexts/UserContext';
import VerificarEmailUsuario from './paginas/usuario/VerificarEmailUsuario';
import ProtectedRoutes from './util/ProtectedRoutes';
import Cookies from 'universal-cookie';
import GerenciamentoAplicacao from './paginas/gerenciamentoAplicacao/GerenciamentoAplicacao';

const Routs = () => {
    const cookies = new Cookies();
    const [userRole, setUserRole] = useState(cookies.get('user') && Boolean(cookies.get('user').usuario.role_id));
    const [haveToken, setHaveToken] = useState(cookies.get('token') && Boolean(cookies.get('token')));
    const [haveUser, setHaveUser] = useState(cookies.get('user') && Boolean(cookies.get('user')));
    const { user_role } = useContext(UserContext);

    useEffect(() => {
        loginService.observable.onToken().subscribe((token) => {
            if (token)
                setHaveToken(true);
            else
                setHaveToken(false);
        });

        loginService.observable.setToken(loginService.getToken());
    }, []);

    useEffect(() => {
        if (user_role) {
            setUserRole(true);
            if (user_role != 1 && user_role != 2) {
                setUserRole(false);
            }
        }
    }, [user_role]);

    return (
        <Routes>
            <Route path='/' element={<Inicio />} />
            <Route path='/login' element={<Login />} />

            <Route path='/garimpo-ilegal' element={<DenunciaGarimpo />} />
            <Route path='/garimpo-ilegal/realizar-denuncia' element={<CreateDenunciaGarimpo />} />
            <Route path='/garimpo-ilegal/acompanhar-denuncia' element={<AcompanharNumeroDenunciaGarimpo />} />
            <Route path='/garimpo-ilegal/acompanhar-denuncia/resumo/*' element={<ResumoDenunciaGarimpo />} />

            <Route path='/email/verify/:id/:hash/:expires/:signature' element={<VerificarEmailUsuario />} />
            <Route path='/reset-password/' element={<ResetPasswordUsuario />} />

            <Route element={<ProtectedRoutes limiter={haveToken} navigateTo="/login" />}>
                <Route element={<ProtectedRoutes limiter={userRole} navigateTo="/pagina-inicial" />}>
                    <Route element={<CreateUsuario />} path='/create-usuario' />
                    <Route element={<ListUsuarios />} path='/list-usuario' />
                    <Route element={<EditUsuario />} path='/edit-usuario/*' />
                </Route>

                <Route element={<PaginaInicial />} path='/pagina-inicial' />
                <Route element={<ListDenunciaGarimpo />} path='/list-denuncia-garimpo' />
                <Route element={<VerDenunciaGarimpo />} path='/ver-denuncia-garimpo/*' />
                
                <Route element={<GerenciamentoAplicacao />} path='/gerenciamento-aplicacao' />
            </Route>
        </Routes>
    )
}
export default Routs;
