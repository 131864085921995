import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'universal-cookie';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { 
   FormHelperText, 
   FormControl,
   InputLabel, 
   MenuItem, 
   Select, 
   SelectChangeEvent, 
   Typography 
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { UserContext } from '../../contexts/UserContext';
import Head from '../../helper/Head';
import api from '../../../config/api.service';
import usuarioService from './service/usuario.service';
import styles from './CreateUsuario.module.css';
import Loading from '../../components/Loading/Loading';
import Toast from '../../components/Toast/Toast';

export default function CreateUsuario(props: any) {
   const { register, handleSubmit, watch, setValue, clearErrors, setError, formState: { errors } } = useForm();
   const [tipoUsuarioList, setTipoUsuarioList] = useState([] as any);
   const [tipoUsuario, setTipoUsuario] = useState('');
   const [loading, setLoading] = useState(false);
   const [toastMessage, setToastMessage] = useState('');
   const [toastIcon, setToastIcon] = useState('');
   const [isToast, setIsToast] = useState(false);
   const { user_role } = useContext(UserContext);
   const navigate = useNavigate();
   const cookies = new Cookies();

   useEffect(() => {
      const getRoles = async () => {
         const resp = await api.get("listRoles");

         if (user_role == 1 || cookies.get('user').usuario.role_id == 1)
                  setTipoUsuarioList(resp.data);
         else
            setTipoUsuarioList([resp.data[resp.data.map((role:any) => role.role_name).indexOf('Comum')]])
      }

      getRoles();

   }, []);

   const handleChangeTipoUsuario = (event: SelectChangeEvent) => {
      clearErrors('tipoUsuario');
      setTipoUsuario(event.target.value);

      tipoUsuarioList.forEach((e: any) => {
         if (e.id == event.target.value)
            setValue('tipoUsuario', e.id);
      });
   };

   const onSubmit = async (data: any) => {
      setLoading(true);
      const resp = await usuarioService.createUsuario(data);

      if(resp.user) {
         setLoading(false);
         setToastMessage('Usuário criado com sucesso!');
         setToastIcon('success');
         setIsToast(true);
         navigate('/list-usuario');
      }
      else {
         setLoading(false);
         setToastMessage('Erro ao cadastrar novo usuário!');
         setToastIcon('error');
         setIsToast(true);
         resp.error.data.forEach((e:any)=>{
            setError(e.label, { message: e.erro });
         })         
      }
   }

   return (
      <section className={`animeRight container-internal ${styles.createUsuario}`}>
         <Toast open={isToast} icon={toastIcon} mensagem={toastMessage} timer={3000} />
         <Head title=" | Criar usuário" />
         <Card className="cardSection">
            <CardContent>
               <div className={styles.cardContent}>
                  <h2 className="section-title">Criar Usuario</h2>
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                     <div className={`row p-2 ${styles.form}`}>
                        <TextField
                           {...register('nome', { required: false })}
                           className='mb-2 w-100'
                           label="Nome (*)"
                           variant="outlined"
                           type='text'
                           autoComplete='off'
                           error={errors.nome ? true : false}
                           helperText={errors.nome && errors.nome.message?.toString()}
                        />

                        <TextField
                           {...register('email', { required: false })}
                           className='mb-2 w-100'
                           label="Email (*)"
                           variant="outlined"
                           // type='email'
                           autoComplete='off'
                           inputProps={{ autoComplete: 'off' }}
                           error={errors.email ? true : false}
                           helperText={errors.email && errors.email.message?.toString()}
                        />

                        <TextField
                           className='mb-2 w-100'
                           label="Senha (*)"
                           variant="outlined"
                           type='password'
                           autoComplete='off'
                           defaultValue="************"
                           disabled
                        />

                        <FormControl error={errors.tipoUsuario ? true : false} >
                           <InputLabel>Tipo usuário</InputLabel>
                           <input {...register('tipoUsuario', { required: false })} hidden />
                           <Select
                              value={tipoUsuario}
                              label='Tipo usuário'
                              onChange={handleChangeTipoUsuario}
                              MenuProps={{ PaperProps: { sx: { maxHeight: 450 } } }}
                           >
                              {!!tipoUsuarioList ?
                                 tipoUsuarioList.map((option: any, i: any) =>
                                    <MenuItem value={option.id} key={i} >{option.role_name}</MenuItem>
                                 )
                                 :
                                 <MenuItem value={0}>Nada a ser exibido</MenuItem>
                              }
                           </Select>
                           {errors.tipoUsuario &&
                              <FormHelperText>{errors.tipoUsuario?.message?.toString()}</FormHelperText>
                           }
                        </FormControl>                        
                        
                        {
                           loading ? 
                           <Loading isLoading={loading} />
                           :
                           <Button variant="contained" type='submit'>
                              Cadastrar
                           </Button>
                        }
                     </div>
                  </form>
               </div>
            </CardContent>
         </Card>
      </section>
   )
}
