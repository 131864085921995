import React from 'react';
import ListaStatus from './ListaStatus';

export default function GerenciamentoAplicacao() {

   return (
      <>
         <ListaStatus/>
      </>
   )
}
