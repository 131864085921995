import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Button, Card, CardContent, FormControl, InputLabel, NativeSelect, Select, SelectChangeEvent } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Head from '../../helper/Head';
import { dataBr } from '../../util/mascaras';
import Loading from '../../components/Loading/Loading';
import ModalAlert from '../../components/ModalAlert/ModalAlert';
import denunciaGarimpoService from './service/denunciaGarimpo.service';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import styles from './ListDenunciaGarimpo.module.css';
import { useForm } from 'react-hook-form';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRight from '@mui/icons-material/ChevronRight';

const theme = createTheme({
   palette: {
      secondary: {
         main: '#053616',
      },
   },
});

const ITEM_HEIGHT = 80;

export default function ListDenunciaGarimpo() {
   const { register, handleSubmit, watch, setValue, clearErrors, setError, formState: { errors } } = useForm();
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [loadingPages, setLoadingPages] = useState(false);
   const [height, setHeight] = useState<Number>();
   const [openModal, setOpenModal] = useState(false);
   const [filterStatus, setFilterStatus] = useState(1);
   const [statusDenuncia, setStatusDenuncia] = useState([] as any);
   const [listDenunciaGarimpo, setListDenunciaGarimpo] = useState([] as any);
   const [pagination, setPagination] = useState({} as any);
   const [page, setPage] = useState(1);
   const [previousButton, setPreviousButton] = useState(false as any);
   const [nextButton, setNextButton] = useState(false as any);

   const [estadosList, setEstadosList] = useState([] as any);
   const [estado, setEstado] = useState('');
   const [cidadesList, setCidadesList] = useState([] as any);
   const [cidade, setCidade] = useState('');

   const [estadoEscolhido, setEstadoEscolhido] = useState('');
   const [cidadeEscolhida, setCidadeEscolhida] = useState('');
   const [statusEscolhido, setStatusEscolhido] = useState(null as any);

   const [listTodosStatusDenuncia, setListTodosStatusDenuncia] = useState([] as any);

   const [anchorElEstado, setAnchorElEstado] = React.useState<null | HTMLElement>(null);
   const openEstado = Boolean(anchorElEstado);

   const handleClickEstado = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElEstado(event.currentTarget);
   };

   const handleCloseEstado = () => {
      setAnchorElEstado(null);
   };

   const [anchorElCidade, setAnchorElCidade] = React.useState<null | HTMLElement>(null);
   const openCidade = Boolean(anchorElCidade);
   const handleClickCidade = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElCidade(event.currentTarget);
   };

   const handleCloseCidade = () => {
      setAnchorElCidade(null);
   };

   const [anchorElStatus, setAnchorElStatus] = React.useState<null | HTMLElement>(null);
   const openStatus = Boolean(anchorElStatus);
   const handleClickStatus = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElStatus(event.currentTarget);
   };

   const handleCloseStatus = () => {
      setAnchorElStatus(null);
   };

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, []);

   useEffect(() => {
      listStatusDenuncia();
      listTodosStatusDenuncias();
   }, []);

   useEffect(() => {
      listDenunciaGarimpos({ 'page': 1, 'status': filterStatus });
      setLoadingPages(true);
      setPage(1);
      setEstadoEscolhido('');
      setCidadeEscolhida('');
      setStatusEscolhido(null);
   }, [filterStatus]);

   useEffect(() => {
      listDenunciaGarimpos({ 'page': page, 'status': filterStatus, 'estado': estadoEscolhido, 'cidade': cidadeEscolhida, 'status_acompanhamento': statusEscolhido });
   }, [page]);

   useEffect(() => {
      fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
         .then((response) => response.json())
         .then((data) => setEstadosList(data));
   }, []);

   useEffect(() => {
      if (!!estado)
         fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios?orderBy=nome`)
            .then((response) => response.json())
            .then((data) => setCidadesList(data));

   }, [estado, listDenunciaGarimpo]);

   const listStatusDenuncia = async () => {
      const resp = await denunciaGarimpoService.listStatusDenuncia();
      const abasDenuncia = [
         {
            "id": 1,
            "name": "Em aberto",
            "type": 1,
         },
         {
            "id": 2,
            "name": "Deferido",
            "type": 1,
         },
         {
            "id": 3,
            "name": "Indeferido",
            "type": 1,
         },
         {
            "id": 4,
            "name": "Arquivados",
            "type": 1,
         }
      ]
      // setStatusDenuncia(abasDenuncia);
      setStatusDenuncia(resp);
   }

   const listTodosStatusDenuncias = async () => {
      const resp = await denunciaGarimpoService.listTodosStatusDenuncias();
      setListTodosStatusDenuncia(resp);
   }

   const listDenunciaGarimpos = async (params: any) => {
      setLoading(true);
      const resp = await denunciaGarimpoService.listDenuncias(params);
      setTimeout(() => {
         setNextButton(resp.pagination.links.next ? true : false);
         setPreviousButton(resp.pagination.links.previous ? true : false);
         setListDenunciaGarimpo(resp.denuncias);
         setPagination(resp.pagination);
         setLoading(false);
         setLoadingPages(false);
      }, 700)
   };

   const handleStatusChange = (event: any, status: any = 0) => {
      let status_id = event.target.value;

      if (status !== 0)
         status_id = status;

      setFilterStatus(status_id);
   };

   const handleChangeEstado = async (event: SelectChangeEvent) => {
      handleCloseEstado();
      setCidadeEscolhida('');
      
      let estado = '';

      setEstado(event.target.value);

      estadosList.forEach((e: any) => {
         if (e.id == event.target.value) {
            estado = e.nome;
            setEstadoEscolhido(e.nome);
         }
      });

      listDenunciaGarimpos({ 'page': 1, 'status': filterStatus, 'estado': estado, 'cidade': '', 'status_acompanhamento': statusEscolhido });
   };

   const handleChangeCidade = async (event: SelectChangeEvent) => {
      handleCloseCidade();

      let cidade = ''

      setCidade(event.target.value);
      cidadesList.forEach((e: any) => {
         if (e.id == event.target.value) {
            cidade = e.nome
            setCidadeEscolhida(e.nome);
         }
      });

      listDenunciaGarimpos({ 'page': 1, 'status': filterStatus, 'estado': estadoEscolhido, 'cidade': cidade, 'status_acompanhamento': statusEscolhido });
   };

   const handleLimpaFiltroEstadoCidade = async () => {
      handleCloseEstado();
      setLoading(true);
      setEstado('');
      setCidadeEscolhida('');
      setCidadesList([])

      listDenunciaGarimpos({ 'page': 1, 'status': filterStatus, 'estado': '', 'cidade': '', 'status_acompanhamento': statusEscolhido });
   }

   const handleLimpaStatus = async () => {
      handleCloseStatus();
      setStatusEscolhido(null);
      listDenunciaGarimpos({ 'page': 1, 'status': filterStatus, 'estado': estadoEscolhido, 'cidade': cidadeEscolhida });
   }

   const handleChangeStatus = async (event: SelectChangeEvent) => {
      handleCloseStatus();
      setStatusEscolhido(event.target.value);

      listDenunciaGarimpos({ 'page': 1, 'status': filterStatus, 'estado': estadoEscolhido, 'cidade': cidadeEscolhida, 'status_acompanhamento': event.target.value });
   }

   const changePage = (page_index: any) => {
      setNextButton(page_index == pagination.totalPages ? false : true);
      setPreviousButton(page_index == 1 ? false : true);
      setPage(page_index);
   }

   const Estados = () => {
      return (
         <Menu
            id="long-menu"
            MenuListProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorElEstado}
            open={openEstado}
            onClose={handleCloseEstado}
            PaperProps={{
               style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
               },
            }}
         >
            <MenuItem value={0} onClick={handleLimpaFiltroEstadoCidade}>Todos</MenuItem>
            {(!!estadosList) ?
               estadosList.map((option: any, i: any) => (
                  <MenuItem value={option.id} key={i} onClick={(e: any) => handleChangeEstado(e)}>{option.nome}</MenuItem>
               )) : <MenuItem value={0}>Nada a ser exibido</MenuItem>
            }
         </Menu>
      )
   }

   const Cidades = () => {
      return (
         <Menu
            id="long-menu"
            MenuListProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorElCidade}
            open={openCidade}
            onClose={handleCloseCidade}
            PaperProps={{
               style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
               },
            }}
         >
            {(!!cidadesList && cidadesList.length > 0) ?
               cidadesList.map((option: any, i: any) => (
                  <MenuItem value={option.id} key={i} onClick={(e: any) => handleChangeCidade(e)}>{option.nome}</MenuItem>
               )) : <MenuItem value={0} onClick={handleCloseCidade}>Escolha um estado</MenuItem>
            }
         </Menu>
      )
   }

   const Status = () => {
      return (
         <Menu
            id="long-menu"
            MenuListProps={{
               'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorElStatus}
            open={openStatus}
            onClose={handleCloseStatus}
            PaperProps={{
               style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '40ch',
               },
            }}
         >
            <MenuItem value={0} onClick={handleLimpaStatus}>Todos</MenuItem>
            {(!!listTodosStatusDenuncia && listTodosStatusDenuncia.length > 0) &&
               listTodosStatusDenuncia?.map((option: any, i: any) => (
                  <MenuItem value={option.id} key={i} onClick={(e: any) => handleChangeStatus(e)}>{option.name}</MenuItem>
               ))
            }
         </Menu>
      )
   }

   return (
      <section className={`animeRight container-internal ${styles.listDenunciaSection}`}>
         <Head title=" | Listagem Denúncias" />
         <Card>
            <CardContent>
               <div className={styles.cardContent}>
                  <h3 className="section-title">Listagem Denúncias de Garimpo Ilegal</h3>
                  {
                     windowWidth > 800
                        ?
                        <div className={styles.tab}>
                           <ThemeProvider theme={theme}>
                              <Box sx={{ width: '100%' }}>
                                 <Tabs
                                    value={filterStatus}
                                    onChange={handleStatusChange}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    aria-label="secondary tabs example"
                                 >
                                    {statusDenuncia.length > 0 &&
                                       statusDenuncia.map((status: any, index: any) => (
                                          <Tab key={index} value={status.id} label={status.name} />
                                       ))
                                    }
                                 </Tabs>
                              </Box>
                           </ThemeProvider>
                        </div>
                        :
                        <div className={styles.selectTab}>
                           <Box sx={{ minWidth: 120 }}>
                              <FormControl fullWidth>
                                 <NativeSelect
                                    defaultValue={filterStatus}
                                    onChange={handleStatusChange}
                                 >
                                    {statusDenuncia.length > 0 &&
                                       statusDenuncia.map((status: any, index: any) => (
                                          <option key={index} value={status.id}>{status.name}</option>
                                       ))
                                    }
                                 </NativeSelect>
                              </FormControl>
                           </Box>
                        </div>
                  }

                  <div className={styles.tableDenuncias}>
                     <table className={`table table-bordered`} border={1}>
                        <thead>
                           <tr>
                              <th scope="col">N° Registro</th>
                              <th scope="col">Objeto da denúncia</th>
                              <th scope="col">
                                 Estado
                                 <IconButton onClick={handleClickEstado}>
                                    <FilterAltIcon />
                                 </IconButton>
                                 <Estados />
                              </th>
                              <th scope="col">Cidade
                                 <IconButton onClick={handleClickCidade}>
                                    <FilterAltIcon />
                                 </IconButton>
                                 <Cidades />
                              </th>
                              <th scope="col">Data da ocorrência</th>
                              <th scope="col">Status
                                 {filterStatus != 4 &&
                                    <>
                                       <IconButton onClick={handleClickStatus}>
                                          <FilterAltIcon />
                                       </IconButton>
                                       <Status />
                                    </>
                                 }
                              </th>
                              <th scope="col">Aberto processo?</th>
                              <th scope="col">Visualizar</th>
                           </tr>
                        </thead>
                        <tbody>
                           {loading ?
                              <tr>
                                 <td colSpan={7} className={styles.noResults}>
                                    <Loading isLoading={loading} />
                                 </td>
                                 <td style={{ display: 'none' }}>
                                 </td>
                              </tr>
                              :
                              (listDenunciaGarimpo.length > 0 ?
                                 listDenunciaGarimpo.map((e: any, i: any) =>
                                 (
                                    (e.idStatusDenuncia == filterStatus || filterStatus == 4) &&
                                    <tr key={i}>
                                       <td>{e.registroDenuncia}</td>
                                       <td>{e.objetoDenuncia}</td>
                                       <td>{e.estado}</td>
                                       <td>{e.cidade}</td>
                                       <td>{dataBr(e.dataOcorrencia)}</td>
                                       <td>{e.status_acompanhamento.name}</td>
                                       <td>{e.nr_processo_sei == null ? 'Não' : 'Sim'}</td>
                                       <td>
                                          <span title="Visualizar denúncia">
                                             <Button onClick={() => navigate('/ver-denuncia-garimpo/' + e.idDenunciaGarimpo)}><VisibilityIcon /></Button>
                                          </span>
                                       </td>
                                    </tr>
                                 )
                                 )
                                 :
                                 <tr>
                                    <td colSpan={6} className={styles.noResults}>
                                       <p>Não há denúncias registradas no sistema.</p>
                                    </td>
                                    <td style={{ display: 'none' }}>
                                    </td>
                                 </tr>
                              )
                           }
                        </tbody>
                     </table>
                  </div>
               </div>
               <div>
                  <div className={styles.paginateSection}>
                     {loadingPages ?
                        <Loading isLoading={loadingPages} />
                        :
                        <>
                           {pagination && previousButton &&
                              <button onClick={() => changePage(page - 1)}>
                                 <ArrowLeft style={{ color: '#053616', fontSize: 25 }} />
                              </button>
                           }

                           {pagination.links && pagination.links.pagination &&
                              Object.entries(pagination.links.pagination).map((item: any, index: any) => (
                                 <button key={index} className={`${page == index + 1 && styles.selectedPage}`} onClick={() => changePage(index + 1)}>{index + 1}</button>
                              ))
                           }

                           {pagination && nextButton &&
                              <button onClick={() => changePage(page + 1)}>
                                 <ArrowRight style={{ color: '#053616', fontSize: 25 }} />
                              </button>
                           }
                        </>
                     }
                  </div>
                  <p className={styles.resultsLenght}>Exibindo {listDenunciaGarimpo.length} resultados de {pagination.quantity}</p>
               </div>
            </CardContent>
         </Card>

         <ModalAlert
            isOpen={openModal}
            setOpenModal={setOpenModal}
            title="Alterar status denúncia"
            body={<span>
               Status: Em análise
            </span>}
         />
      </section>
   )
}
